














import { Component, Prop, Vue } from 'vue-property-decorator';

import TracksTable, { SearchOptions } from '@/components/TracksTable.vue';
import TracksFilters from '@/components/drawer/TracksFilters.vue';
import { getModule } from 'vuex-module-decorators';
import { GuiModule } from '@/store/modules/gui';
import _debounce from 'lodash/debounce';
import { Track } from '../../service/types';
import { getPlayer } from '../../service/audio';

@Component({
  components: {
    TracksTable,
    TracksFilters
  }
})
export default class Tracks extends Vue {
  searchOptions: SearchOptions | null = null;

  updateSearchOptions(options: SearchOptions) {
    this.$set(this, 'searchOptions', options);
  }

  created() {
    this.updateSearchOptions = _debounce(this.updateSearchOptions, 1000, {
      leading: false,
      trailing: true
    });
  }

  mounted() {
    const gui = getModule(GuiModule, this.$store);

    gui.setDrawerEnabled(true);
  }

  beforeDestroy() {
    const gui = getModule(GuiModule, this.$store);

    gui.setDrawerEnabled(false);
  }
}
